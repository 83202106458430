import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import GhostsChat from "./components/chat";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "./style/main.css";
import "./fonts/Creepster/Creepster-Regular.ttf";

const useStyles = makeStyles({
  App: {
    height: "100%",
    maxWidth: "1280px",
    backgroundColor: "#383a59",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
    borderRadius: "8px",
    padding: "0",
  },
});

const App = () => {
  const classes = useStyles();
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", (event) =>
      setScreenSize(window.innerWidth),
    );
  });

  return (
    <Container maxWidth="sm" className={classes.App}>
      <GhostsChat screenSize={screenSize} />
    </Container>
  );
};

const root = createRoot(document.querySelector("#root"));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
    window.location.reload();
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

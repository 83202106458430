import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  MessageContainer: {
    marginBottom: "8px",
    padding: "8px",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
    borderRadius: "8px",
    backgroundColor: "#2e2e30",
  },
  MessageContainerAvatar: {
    fontFamily: "Press_Start_2P",
    fontSize: "0.7em",
  },
  MessageItem1: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  MessageItem1Typography: {
    flex: "1",
    paddingLeft: "0.5em",
    fontFamily: "Press_Start_2P",
    fontSize: "0.7em",
  },
  MessageItem2: {
    display: "flex",
  },
  MessageItem2Typography: {
    flex: "1",
    paddingLeft: "0.5em",
    fontFamily: "Press_Start_2P",
    fontSize: "0.7em",
    lineBreak: "anywhere",
  },
});

const Message = ({
  user: { avatar, name },
  message,
  fromLocalhost = false,
}) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.MessageContainer}
      container
      direction="column"
      alignItems={fromLocalhost ? "flex-end" : "flex-start"}
      justifyContent={fromLocalhost ? "flex-end" : "flex-start"}
    >
      <Grid className={classes.MessageItem1} item>
        <Avatar className={classes.MessageContainerAvatar} src={avatar} />
        <Typography className={classes.MessageItem1Typography}>
          {name}
        </Typography>
      </Grid>
      <Grid className={classes.MessageItem2} item>
        <Typography
          className={classes.MessageItem2Typography}
          dangerouslySetInnerHTML={{ __html: message }}
        ></Typography>
      </Grid>
    </Grid>
  );
};

export default Message;

import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  GhostsChatRootContainer: {
    paddingLeft: "0px",
    paddingRight: "0px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
});

const ChatContainer = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Container {...props} className={classes.GhostsChatRootContainer}>
      {children}
    </Container>
  );
};

export default ChatContainer;

import { makeStyles } from "@material-ui/core/styles";
import ChatParticipants from "./participants";
import Grid from "@material-ui/core/Grid";
import Chat from "./chat";

const useStyles = makeStyles({
  ChatBodyBox: {
    flex: "1",
    display: "flex",
    padding: "8px",
  },
});

const ChatBody = ({ screenSize, user, users }) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.ChatBodyBox}
      container
      justifyContent="space-between"
      alignItems="stretch"
      spacing={screenSize >= 1280 ? 2 : 0}
    >
      {screenSize >= 1280 && (
        <ChatParticipants item xs={2} room={user?.room} users={users} />
      )}
      <Chat
        item
        xs={screenSize >= 1280 ? 10 : 12}
        screenSize={screenSize}
        user={user}
      />
    </Grid>
  );
};

export default ChatBody;

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  ChatFooterDivTypography: {
    fontFamily: "Press_Start_2P",
    width: "100%",
    overflow: "auto",
    wordWrap: "break-word",
    padding: "15px 5px",
    fontSize: (prop) => (prop.screenSize <= 650 ? "0.6em" : ""),
  },
});

const ChatFooter = ({ screenSize }) => {
  const classes = useStyles({ screenSize });
  return (
    <Typography className={classes.ChatFooterDivTypography} align="center">
      Donate: 0x1b07fdaffb46c1dcbc6cb7858687a337eac86c6f
    </Typography>
  );
};

export default ChatFooter;

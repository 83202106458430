import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import MenuMobile from "../menu/menuMobile";

const useStyles = makeStyles({
  GhostsChatHeaderTypography: {
    fontFamily: "Creepster",
    fontSize: (props) => (props.screenSize <= 768 ? "2em" : "4em"),
  },
});

const ChatHeader = ({ screenSize, user, users }) => {
  const classes = useStyles({ screenSize });
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      {screenSize < 1280 && (
        <Grid item xs={1}>
          <MenuMobile user={user} users={users} />
        </Grid>
      )}
      <Grid item xs={screenSize < 1280 ? 11 : 12}>
        <Typography
          className={classes.GhostsChatHeaderTypography}
          align="center"
        >
          Ghosts Chat
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ChatHeader;

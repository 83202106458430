import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useRef } from "react";

const useStyles = makeStyles({
  ChatMessages: {
    flex: "1 1 0",
    padding: "8px",
    margin: "0 0 8px 0",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
    borderRadius: "8px",
    overflowX: "hidden",
    overflowY: "auto",
  },
  ChatMessagesDiv: {
    width: "100%",
    height: "auto",
    border: "none",
    color: "#9193a3",
    padding: "8px",
    borderRadius: "8px",
    outline: "none",
    fontFamily: "Press_Start_2P",
  },
  ChatMessagesTypography: {
    fontFamily: "Press_Start_2P",
    fontSize: "1em",
    padding: "8px",
    borderRadius: "8px",
  },
});

const ChatMessages = ({ messages, ...props }) => {
  const classes = useStyles();

  const dummyScroll = useRef(null);

  useEffect(() => {
    dummyScroll.current.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <Grid {...props} className={classes.ChatMessages}>
      <Typography className={classes.ChatMessagesTypography} align="center">
        Messages
      </Typography>
      <Box className={classes.ChatMessagesDiv}>{messages}</Box>
      <div ref={dummyScroll} />
    </Grid>
  );
};

export default ChatMessages;

export default class User {
  #socketId = null;
  get socketId() {
    return this.#socketId;
  }

  #name = null;
  get name() {
    return this.#name;
  }

  #avatar = null;
  get avatar() {
    return this.#avatar;
  }

  #room = {
    id: null,
    name: null,
  };
  get room() {
    return this.#room;
  }

  constructor({ socketId, name, avatar, room }) {
    this.#socketId = socketId;
    this.#name = name;
    this.#avatar = avatar;
    this.#room = room;
  }

  toJson() {
    return {
      socketId: this.#socketId,
      name: this.#name,
      avatar: this.#avatar,
      room: this.#room,
    };
  }
}

import io from "socket.io-client";

const HOST =
  process.env.NODE_ENV === "production"
    ? "https://api.ghostschat.com"
    : "http://localhost:3001";

const socket = io(HOST).connect();

console.info("Socket is active?", socket.active);

export { socket };

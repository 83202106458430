import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Send from "@material-ui/icons/Send";
import MeetingRoom from "@material-ui/icons/MeetingRoom";
import User from "../../state/userState";
import { useState } from "react";
import { socket } from "../../api/socket";
import { v4 } from "uuid";
import { DialogStyled } from "./dialogUtils";
import { faker } from "@faker-js/faker";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  DialogAvatarContainer: {
    display: "flex",
  },
  DialogAvatarContainerItem: {
    marginLeft: "auto",
    marginRight: "auto",
    height: (props) => (props.screenSize <= 650 ? "60px" : ""),
    width: (props) => (props.screenSize <= 650 ? "60px" : ""),
  },
  endIcon: {
    marginLeft: (props) => (props.screenSize <= 650 ? "0" : "8px"),
    marginRight: (props) => (props.screenSize <= 650 ? "0" : "-4px"),
  },
});

const ChatDialog = ({ setUser, screenSize }) => {
  const classes = useStyles({ screenSize });
  const [errors, setErrors] = useState([]);
  const [roomId, setRoomId] = useState(v4());
  const [roomName, setRoomName] = useState(
    `${faker.word.verb()} ${faker.word.adverb()}`,
  );
  const [name, setName] = useState(faker.name.fullName());
  const [avatar, setAvatar] = useState(faker.image.avatar());
  const [roomNameStyle, setRoomNameStyle] = useState({});
  const [btnJoinRoomXS, setBtnJoinRoomXS] = useState(5);
  const joinRoomTexts = ["or join a room", "new room"];
  const [joinRoomText, setJoinRoomText] = useState(joinRoomTexts[0]);
  const [gridJoinRoomSyle, setGridJoinRoomSyle] = useState({});

  const validateInputs = () => {
    const errors = [];
    if (!roomId || !roomId.length || roomId.length < 5) {
      errors.push(
        "Room id can't be null, empty, or have less than five characters!",
      );
    }
    if (!name || !name.length || name.length < 5) {
      errors.push(
        "Name can't be null, empty, or have less than five characters!",
      );
    }
    if (
      !avatar ||
      !avatar.length ||
      avatar.length < 5 ||
      avatar.indexOf("http") < 0
    ) {
      errors.push("Avatar must be a valid URL of image!");
    }
    return errors;
  };

  const onClickBtnSend = () => {
    const errors = validateInputs();
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }
    const room = { id: roomId, name: roomName };
    const currentUser = new User({
      socketId: socket.id,
      name,
      avatar,
      room,
    });
    setUser(currentUser);
    const user = currentUser.toJson();
    socket.emit("joinRoom", { room, user });
    socket.emit("getRoomData", { roomId: room?.id });
    setErrors([]);
  };

  const onClickBtnJoinRoom = () => {
    const nextIndex = joinRoomText === joinRoomTexts[1] ? 0 : 1;
    setRoomId(nextIndex === 0 ? v4() : "");
    setBtnJoinRoomXS(nextIndex === 0 ? 5 : 12);
    setRoomNameStyle(
      nextIndex === 0 ? { display: "block" } : { display: "none" },
    );
    setJoinRoomText(joinRoomTexts[nextIndex]);
    setGridJoinRoomSyle(nextIndex === 0 ? {} : { marginBottom: "0px" });
  };

  return (
    <DialogStyled open>
      <DialogTitle>Who are you ?</DialogTitle>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            id="roomId"
            label="Room ID"
            value={roomId}
            onChange={(event) => setRoomId(event?.target?.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={screenSize <= 650 ? 12 : 7} style={roomNameStyle}>
              <TextField
                id="roomName"
                label="Room Name"
                value={roomName}
                onChange={(event) => setRoomName(event?.target?.value)}
              />
            </Grid>
            <Grid
              item
              xs={screenSize <= 650 ? 12 : btnJoinRoomXS}
              style={gridJoinRoomSyle}
            >
              <Button
                variant="contained"
                size="large"
                endIcon={<MeetingRoom />}
                onClick={onClickBtnJoinRoom}
              >
                {joinRoomText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="name"
            label="Your name"
            value={name}
            onChange={(event) => setName(event?.target?.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid
              className={classes.DialogAvatarContainer}
              item
              xs={screenSize <= 650 ? 12 : 1}
            >
              <Avatar
                className={classes.DialogAvatarContainerItem}
                src={avatar}
              />
            </Grid>
            <Grid item xs={screenSize <= 650 ? 12 : 11}>
              <TextField
                id="avatar"
                label="Avatar URL"
                value={avatar}
                onChange={(event) => setAvatar(event?.target?.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            endIcon={<Send />}
            onClick={onClickBtnSend}
          >
            Confirm
          </Button>
        </Grid>
        {errors.length > 0 && (
          <ul>
            {errors.map((error, index) => (
              <li key={`dialog-error-${index}`}>{error}</li>
            ))}
          </ul>
        )}
      </Grid>
    </DialogStyled>
  );
};

export default ChatDialog;

import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import { DialogStyled } from "./dialogUtils";
import ChatParticipant from "../chat/participant";
import { makeStyles } from "@material-ui/core/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const useStyles = makeStyles({
  MobileChatParticipantsRoom: {
    alignItems: "center",
    padding: "0.5em",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
    borderRadius: "8px",
    marginTop: "10px",
    "& .MuiTypography-root": {
      fontSize: "0.6em",
      fontFamily: "Press_Start_2P",
      marginBottom: "8px",
    },
  },
});

const ParticipantsDialog = ({ user, users, closeDialog }) => {
  const classes = useStyles();
  const onClick = () => {
    closeDialog();
  };
  const copyToClipboard = () => navigator.clipboard.writeText(user?.room?.id);
  return (
    <DialogStyled open>
      <Grid container>
        <Grid item xs={12}>
          <Grid className={classes.MobileChatParticipantsRoom} container>
            <Grid item xs={12}>
              <Typography align="left">ROOM</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left">ID: {user?.room?.id}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left">NAME: {user?.room?.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <ContentCopyIcon
                fontSize="small"
                sx={{ "&:hover": { color: "green" } }}
                onClick={copyToClipboard}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {users &&
              users.length &&
              users.map((user, index) => {
                const participant = {
                  ...(<ChatParticipant user={user} />),
                  key: `mobile-chat-participant-${index}`,
                };
                return (
                  <Grid item xs={12}>
                    {participant}
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" endIcon={<Close />} onClick={onClick}>
            Close
          </Button>
        </Grid>
      </Grid>
    </DialogStyled>
  );
};

export default ParticipantsDialog;

import Grid from "@material-ui/core/Grid";
import ChatMessages from "./chatMessages";
import ChatTextArea from "./chatTextArea";
import { makeStyles } from "@material-ui/core/styles";
import Message from "./message";
import { useState, useEffect } from "react";
import { socket } from "../../api/socket";

const useStyles = makeStyles({
  Chat: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "0",
    padding: "8px",
  },
});

const Chat = ({ screenSize, user, ...props }) => {
  const { name, room, avatar, socketId } = user;

  const classes = useStyles();

  const [messages, setMessages] = useState([]);

  const onBtnSendClick = (event, message) => {
    socket.emit("sendMessage", {
      user: {
        id: socketId,
        avatar,
        name,
      },
      room,
      message,
    });
  };

  useEffect(() => {
    socket.on("receiveMessage", (data) => {
      const { user, message } = data;
      const newMessage = {
        ...(
          <Message
            user={user}
            fromLocalhost={socket.id === user?.id}
            message={message}
          />
        ),
        key: `message-${messages.length + 1}`,
      };
      setMessages([...messages, newMessage]);
    });
  }, [messages]);

  return (
    <Grid {...props} className={classes.Chat}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
      >
        <ChatMessages item messages={messages} />
        <ChatTextArea
          item
          screenSize={screenSize}
          onBtnSendClick={onBtnSendClick}
        />
      </Grid>
    </Grid>
  );
};

export default Chat;

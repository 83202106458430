import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  ChatParticipant: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5em",
    padding: "0.5em",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
    borderRadius: "8px",
  },
  ChatParticipantAvatar: {
    fontFamily: "Press_Start_2P",
    fontSize: "0.7em",
  },
  ChatParticipantTypography: {
    flex: "1",
    paddingLeft: "0.5em",
    fontFamily: "Press_Start_2P",
    fontSize: "0.7em",
  },
});

const ChatParticipant = ({ user }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.ChatParticipant} item>
      <Avatar className={classes.ChatParticipantAvatar} src={user.avatar} />
      <Typography className={classes.ChatParticipantTypography} noWrap>
        {user.name}
      </Typography>
    </Grid>
  );
};

export default ChatParticipant;

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import ParticipantsDialog from "../dialog/participantsDialog";

const MenuMobile = ({ user, users }) => {
  const [shouldShowParticipantsDialog, setShouldShowParticipantsDialog] =
    useState(false);

  const onClick = () => {
    setShouldShowParticipantsDialog(!shouldShowParticipantsDialog);
  };

  return (
    <div>
      <IconButton aria-label="more" id="long-button" onClick={onClick}>
        <MoreVertIcon />
      </IconButton>
      {shouldShowParticipantsDialog && (
        <ParticipantsDialog
          user={user}
          users={users}
          closeDialog={() => setShouldShowParticipantsDialog(false)}
        />
      )}
    </div>
  );
};

export default MenuMobile;

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Send from "@material-ui/icons/Send";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";

const useStyles = makeStyles({
  ChatTextArea: {
    padding: "8px",
    margin: "0",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
    borderRadius: "8px",
  },
  ChatTextAreaTextArea: {
    width: "100%",
    height: "100%",
    border: "none",
    backgroundColor: "#2e2e30",
    color: "#9193a3",
    padding: "8px",
    borderRadius: "8px",
    outline: "none",
    fontFamily: "Press_Start_2P",
    fontSize: "0.7em",
  },
  ChatTextAreaButton: {
    width: "100%",
    height: "100%",
    fontFamily: "Press_Start_2P",
    fontSize: "0.7em",
    minWidth: (props) => (props.screenSize <= 650 ? "fit-content" : "64px"),
  },
  endIcon: {
    marginLeft: (props) => (props.screenSize <= 650 ? "0" : "8px"),
    marginRight: (props) => (props.screenSize <= 650 ? "0" : "-4px"),
  },
});

const ChatTextArea = ({ screenSize, onBtnSendClick, ...props }) => {
  const classes = useStyles({ screenSize });
  const [message, setMessage] = useState("");
  const [{ hasError, errorMessage }, setError] = useState({
    hasError: false,
    errorMessage: "",
  });

  const onClick = (event) => {
    if (!message) {
      setError({
        hasError: true,
        errorMessage: "Massage can't be null or empty!",
      });
      return;
    }
    if (message.length > 1000) {
      setError({
        hasError: true,
        errorMessage: "Massage can't have more than 1000 characters.",
      });
      return;
    }
    onBtnSendClick(event, message);
    setError({ hasError: false, errorMessage: "" });
    setMessage("");
  };

  return (
    <Grid {...props} className={classes.ChatTextArea}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
      >
        {hasError && (
          <Grid item xs={12}>
            <Alert
              sx={{
                fontFamily: "Press_Start_2P",
                fontSize: "0.7em",
              }}
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() =>
                    setError({ hasError: false, errorMessage: "" })
                  }
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {errorMessage}
            </Alert>
          </Grid>
        )}
        <Grid item xs={screenSize <= 440 ? 9 : 10}>
          <textarea
            value={message}
            className={classes.ChatTextAreaTextArea}
            placeholder="Message"
            onChange={(event) => setMessage(event?.target?.value)}
          ></textarea>
        </Grid>
        <Grid item xs={screenSize <= 440 ? 3 : 2}>
          <Button
            className={classes.ChatTextAreaButton}
            classes={{ endIcon: classes.endIcon }}
            variant="contained"
            endIcon={<Send />}
            onClick={onClick}
          >
            {screenSize <= 650 ? "" : "Send"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChatTextArea;

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ChatParticipant from "./participant";
import { makeStyles } from "@material-ui/core/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const useStyles = makeStyles({
  ChatParticipantsTypography: {
    fontFamily: "Press_Start_2P",
    fontSize: "1em",
    padding: "8px",
    borderRadius: "8px",
  },
  ChatParticipantsRoom: {
    alignItems: "center",
    marginBottom: "0.5em",
    padding: "0.5em",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
    borderRadius: "8px",
    "& .MuiTypography-root": {
      fontSize: "0.6em",
      fontFamily: "Press_Start_2P",
      marginBottom: "8px",
    },
  },
});

const ChatParticipants = ({ users, room, ...props }) => {
  const classes = useStyles();
  const copyToClipboard = () => navigator.clipboard.writeText(room?.id);
  return (
    <Grid {...props}>
      <Typography className={classes.ChatParticipantsTypography} align="center">
        Participants
      </Typography>
      {room?.id && room?.name && (
        <div className={classes.ChatParticipantsRoom}>
          <Typography align="center">ROOM</Typography>
          <Typography align="left">Name: {room.name}</Typography>
          <Typography align="left">ID: {room.id}</Typography>
          <ContentCopyIcon
            fontSize="small"
            sx={{ "&:hover": { color: "green" } }}
            onClick={copyToClipboard}
          />
        </div>
      )}
      {users?.length > 0 &&
        users.map((user, index) => {
          const participant = {
            ...(<ChatParticipant user={user} />),
            key: `chat-participant-${index}`,
          };
          return participant;
        })}
    </Grid>
  );
};

export default ChatParticipants;

import ChatContainer from "../custom/ChatContainer";
import ChatBody from "./body";
import ChatHeader from "./header";
import ChatDialog from "../dialog/chatDialog";
import { useState, useEffect } from "react";
import { socket } from "../../api/socket";
import User from "../../state/userState";
import ChatFooter from "./footer";

const GhostsChat = ({ screenSize }) => {
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);

  useEffect(() => {
    socket.on("newUserJoin", ({ room, users }) => setUsers(users));
    socket.on("sendRoomData", ({ room }) => {
      if (user?.room?.id === room?.id) {
        setUser(
          new User({
            socketId: user.socketId,
            avatar: user.avatar,
            name: user.name,
            room: {
              id: room.id,
              name: room.name,
            },
          }),
        );
      }
    });
  });

  return (
    <ChatContainer>
      <ChatHeader screenSize={screenSize} user={user} users={users} />
      <ChatBody screenSize={screenSize} user={user} users={users} />
      {!user?.socketId && (
        <ChatDialog setUser={setUser} screenSize={screenSize} />
      )}
      <ChatFooter screenSize={screenSize} />
    </ChatContainer>
  );
};

export default GhostsChat;

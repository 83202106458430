import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";

const DialogStyled = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "0 8px",
    backgroundColor: "#383a59",
    "& .MuiGrid-container > .MuiGrid-grid-xs-12": {
      marginBottom: "10px",
    },
    "& .MuiTypography-root": {
      fontFamily: "Press_Start_2P",
      fontSize: "0.7em",
    },
    "& .MuiInputBase-root, & .MuiFormControl-root": {
      width: "100%",
      "& .MuiFormLabel-root, & .MuiInputBase-input": {
        fontFamily: "Press_Start_2P",
        fontSize: "0.7em",
      },
    },
    "& .MuiButtonBase-root": {
      width: "100%",
      fontFamily: "Press_Start_2P",
      fontSize: "0.7em",
    },
    "& .MuiGrid-container > .MuiGrid-grid-xs-12 > .MuiGrid-container > .MuiGrid-grid-xs-5":
      {
        height: "auto",
      },
    "& .MuiGrid-container > .MuiGrid-grid-xs-12 > .MuiGrid-container > .MuiGrid-grid-xs-5 > button":
      {
        height: "100%",
      },
  },
}));

export { DialogStyled };
